<template>
  <div class="page onboarding bg-neutral-1">
    <div class="onboarding__logo">
      <ImagesSvg icon="logo" />
    </div>
    <NuxtErrorBoundary>
      <LazyOnboardingCarousel />
      <template #error="{ clearError }">
        <AlertsErrorTryAgain :button-action="clearError" />
      </template>
    </NuxtErrorBoundary>
    <div class="onboarding__buttons">
      <ButtonsBaseButton
        type="button"
        variant="solid"
        color="orange"
        @click="handleSignin({ callbackUrl: routes.home.path })">
        {{ contentStore.content?.loginShort }}
      </ButtonsBaseButton>
      <ButtonsBaseButton :as-link="true" :to="routes.home.path" variant="outline" color="green">
        {{ contentStore.content?.continueAsGuest }}
      </ButtonsBaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'empty',
});

const contentStore = useContentStore();
const { handleSignin } = useSignin();
</script>

<style lang="scss" scoped>
.onboarding {
  padding-inline: px(16);
  padding-bottom: px(16);

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: px(22);
    margin-bottom: px(12);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: px(16);
  }
}
</style>
